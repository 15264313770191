.App {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.nav-link {
	cursor: pointer;
}

.skillicon {
	height: 28px;
}

.b-input {
	border: 1.8px solid #ffffff;
}

.hero-text {
	font-size: 2.55rem;
}

@media (min-width: 1024px) {
	.sm\:w-128 {
		width: 28rem;
	}

	.px-menu {
		padding-right: 120px;
		padding-left: 120px;
	}
}

@media (max-width: 1024px) {
	.hero-text {
		font-size: 1.875rem;
	}
}

@media (max-width: 640px) {
	.sm\:w-128 {
		width: 20rem;
	}

	.hero-text {
		font-size: 1.5rem;
	}
}
